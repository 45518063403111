import Bugsnag from '@bugsnag/js';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const AsyncButton = ({ onClick, buttonClass, size, variant, children }) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    try {
      
      setLoading(true);
      await onClick();
    } catch (error) {
      Bugsnag.notify(error)
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button className={buttonClass} onClick={handleClick} size={size} variant={variant}>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        children
      )}
    </Button>
  );
}

export default AsyncButton