import { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useFirebase } from '../firebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { firebaseUser, loginUser } = useFirebase();

  const submit = async (e) => {
    e.preventDefault();
    if (email.endsWith('@tulabalanced.com')) {
      await loginUser(email, password);
    }
  };

  return (
    <>
      <div className="h-100 w-100 d-flex align-items-center justify-content-center">
        <div className="d-flex flex-column w-50 h-50 bg-secondary text-center p-5">
          <h1 className="fs-4">Welcome to the TULA Admin Dashboard</h1>
          <h2 className="fs-5">Please login to continue</h2>

          <Form className="pt-5">
            <Form.Control
              required
              className="w-50 mx-auto"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control
              required
              className="w-50 mx-auto mt-3"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              onClick={submit}
              className="w-50 mx-auto mt-3"
              variant="primary"
              type="submit"
            >
              Login
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
