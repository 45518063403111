import { dateFormat } from "../utils/format";

const CustomerSubscription = ({customer}) => {
  return (
    <>
      {customer.fields &&
      customer.fields.relatedSubscriptions &&
      customer.fields.subscriptionStatus[0] === 'active' ? (
        <>
          <span>{customer.fields.subscriptionHourAmount} hours - {customer.fields.subscriptionBillingCycle[0]}</span>
          <br />
          <span>
            Renewal on {dateFormat(customer.fields.subscriptionBillingDate[0])}
          </span>
          <br />
          <span>
            Next hour deposit on  {dateFormat(customer.fields.nextDepositDate[0])}
          </span>
          {customer.fields.subscriptionCanceledOn && (
            <>
              <br />
              <span className="text-danger pt-2">
                Subscription Canceled on{' '}
                {dateFormat(customer.fields.subscriptionCanceledOn[0])}
              </span>
            </>
          )}
        </>
      ) : (
        'No Active Subscription'
      )}
    </>
  );
};

export default CustomerSubscription;
