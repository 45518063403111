import { useFirebase } from "../firebase";
import {
  getFirestore,
  doc,
  addDoc,
  updateDoc,
  setDoc,
  getDocs,
  collection,
  onSnapshot,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes, deleteObject, getStorage } from "firebase/storage";

import { Button, Col, Container, Form, InputGroup, ListGroup, Row } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import Avatar from 'react-avatar';
import fileDownload from "js-file-download";
import Axios from "axios";
import ChatModal from "./ChatModal";

const Chat = ({showControls, customerName, assistantName}) => {
  const { firestore } = useFirebase();
  const storage = getStorage();


  const [chats, setChats] = useState([]);
  const [customers, setCustomers] = useState({});
  const [assistants, setAssistants] = useState({});
  const [filterQuery, setFilterQuery] = useState('');
  const [filteredChats, setFilteredChats] = useState([]);

  const [assistantChat, setAssistantChat] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (chats.length === 0) {
      getUsers();
    }
  }, []);

  useEffect(() => {
    getChats();
  }, [customers, assistants]);

  useEffect(() => {
    let unsortedChats = [];
    if (assistantChat) {
      if (filterQuery) {
        const filteredChats = chats.filter((chat) => {
          return !chat.admin && (chat.customer.name.toLowerCase().includes(filterQuery.toLowerCase()) || chat.assistant && chat.assistant.name.toLowerCase().includes(filterQuery.toLowerCase()));
        });
        unsortedChats = filteredChats;
      } else {
        unsortedChats = chats;
      }
    } else {
      unsortedChats = chats.filter((chat) => {
        return chat.admin
      })
    }
    
    const sortedChats = unsortedChats.sort((a, b) => {
      if (a.messages.length === 0 || b.messages.length === 0) {
        return 0
      }
      const aDate = a.messages[a.messages.length - 1].createdAt.toDate();
      const bDate = b.messages[b.messages.length - 1].createdAt.toDate();
      return bDate - aDate;
    });
    setFilteredChats(sortedChats);
  }, [chats, filterQuery, assistantChat])

  useEffect(() => {
    if (customerName && assistantName && chats.length > 0) {
      const c = chats.filter((chat) => {
        return chat.customer.name === customerName && chat.assistant.name === assistantName;
      })
      debugger;
      if (c.length > 0) {
        const chat = c[0];
        debugger;
        setSelectedChat(chat);
      }
    }
  }, [customerName, assistantName])


  const getChats = async () => {
    if (
      Object.keys(customers).length > 0 &&
      Object.keys(assistants).length > 0
    ) {
      const chatsCollection = collection(firestore, 'chats');
      const q = query(chatsCollection);
      onSnapshot(q, (snapshot) => {
        const c = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          const customer = customers[data.customer.ref.id];
          let assistant;
          let name;
          if (customer) {
            if (data.assistant) {
              assistant =
                assistants[data.assistant.ref.id];
              if (assistant) {
                name = `${customer.name} <-> ${assistant.name}`
              }
            } else {
              name = `${customer.name}`
            }
          
          if (name) {
            c.push({
              id: doc.id,
              customer: customer,
              assistant: assistant,
              name: name,
              messages: data.messages,
              admin: data.admin
            });
          }
        }
        });
        setChats(c);
      });
    }
  };

  const getUsers = async () => {
    const customerCollection = collection(firestore, 'customers');
    const assistantCollection = collection(firestore, 'assistants');
    const cus = await getDocs(customerCollection).then((querySnapshot) => {
      const c = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        c[doc.id] = data;
      });
      return c;
    });
    const ass = await getDocs(assistantCollection).then((querySnapshot) => {
      const a = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        a[doc.id] = data;
      });
      return a;
    });
    setCustomers(cus);
    setAssistants(ass);
  };

  const selectChat = async (chat) => {
    console.log(chat)
    setSelectedChat(chat)
  }

  const downloadFile = async (file) => {
    const remoteUrl = await getDownloadURL(ref(storage, file.path))
    Axios.get(remoteUrl, {
      responseType: 'blob'
    }).then((res) => {
      fileDownload(res.data, file.name)
    })
  }

  const addAdminChat = async (customer) => {
    console.log(customer)
    // Check if customer already has admin chat

    let currentChat = filteredChats.find((x) => {
      x.customer == customer.id
    })
    if (currentChat) {
      setSelectedChat(currentChat)
    } else {
      const customerRef = doc(firestore, "customers", customer.id)
      const newDoc = await addDoc(collection(firestore, "chats"), {
        messages: [],
        customer: {
          ref: customerRef,
          isUnread: false
        },
        admin: true
      })
      const chat = {
        id: newDoc.id,
        customer: customers[newDoc.customer.ref.id],
        name: customer.fields.name,
        messages: newDoc.messages,
        admin: true
      }
      setFilteredChats([chat].concat(filteredChats))
      setShowModal(false)
    }
  }

  return (
    <Container>
      <Row>
        <Col
          xs="4"
          style={{ height: '80vh', display: showControls ? 'block' : 'none' }}
        >
          <InputGroup className="mb-2">
            <InputGroup.Text>Search</InputGroup.Text>
            <Form.Control
              onChange={(event) => {
                setFilterQuery(event.target.value);
              }}
            />
          </InputGroup>
          {/* <div className="d-flex flex-row align-items-center justify-content-between">
            <Form.Check
              inline
              name="chatType"
              type="switch"
              label={assistantChat ? 'Assistant Chat' : 'Admin Chat'}
              defaultChecked={assistantChat}
              onChange={(event) => setAssistantChat(!assistantChat)}
            />
            {!assistantChat && (
            <Button size="sm" variant="outline-info" onClick={() => {setShowModal(true)}}>
              Add Client Admin Chat
            </Button>
            )}
          </div> */}
          <div className="fw-bold d-flex flex-row justify-content-between">
            <span>Client</span>
            {assistantChat && <span className="text-right">Assistant</span>}
          </div>
          <ListGroup className="overflow-y-scroll h-100">
            {filteredChats.map((chat, index) => {
              return (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={() => {
                    selectChat(chat);
                  }}
                  xs="4"
                  className={`${
                    index % 2 === 0 ? 'bg-primary rounded' : ''
                  }  fs-6 ps-2 py-2 d-flex align-items-center justify-content-between
                ${
                  selectedChat && selectedChat.id === chat.id ? 'fw-bold' : ''
                }`}
                >
                  <div>
                    <Avatar
                      name={chat.customer.name}
                      size={30}
                      round
                      src={chat.customer?.avatarUrl}
                      className="me-1"
                    />
                    <span className="ps-1">{chat.customer.name}</span>
                  </div>
                  {chat.assistant && (
                    <div>
                      <Avatar
                        name={chat.assistant.name}
                        size={30}
                        round
                        src={chat.assistant?.avatarUrl}
                        className="mx-1"
                      />
                      <span className="ps-1">{chat.assistant.name}</span>
                    </div>
                  )}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
        <Col
          className="d-flex flex-column overflow-y-scroll pb-24"
          style={{ height: '80vh' }}
        >
          {selectedChat && (
            <>
              <div className="fw-bold d-flex flex-row justify-content-between">
                <span>{selectedChat.customer.name}</span>
                {selectedChat.assistant && (
                  <span className="text-right">
                    {selectedChat.assistant.name}
                  </span>
                )}
              </div>
              {selectedChat.messages.map((message, index) => {
                return (
                  <>
                    <div
                      key={index}
                      style={{ maxWidth: '90%' }}
                      className={`my-2 p-2 pb-0 rounded border ${
                        message.user === 'assistant'
                          ? 'align-self-end bg-primary'
                          : 'align-self-start'
                      }`}
                    >
                      {message.text}

                      {message.images &&
                        message.images.map((image) => (
                          <>
                            <br />
                            <br />
                            <img
                              src={image}
                              alt="chat"
                              style={{ maxWidth: '60%' }}
                            />
                          </>
                        ))}

                      {message.files &&
                        message.files.map((file) => (
                          <>
                            <br />
                            <span
                              className="fw-bold"
                              role="button"
                              onClick={() => downloadFile(file)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-paperclip"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                              </svg>
                              {file.name}
                            </span>
                          </>
                        ))}

                      <p
                        style={{ fontSize: '.8rem' }}
                        className="text-end pt-4"
                      >
                        {message.createdAt.toDate().toLocaleString()}
                      </p>
                    </div>
                  </>
                );
              })}
            </>
          )}
        </Col>
      </Row>
      <ChatModal
        showModal={showModal}
        setShowModal={setShowModal}
        addChat={addAdminChat}
      />
    </Container>
  );
};

export default Chat