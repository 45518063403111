export const dateFormat = (dateString) => {
  if (!dateString) {
    return "";
  }
  dateString = dateString.includes('T') ? dateString : `${dateString}T12:00:00`;
  const d = new Date(dateString)
  return d.toLocaleDateString();
};

export const dateTimeFormat = (dateTimeString) => {
  return new Date(dateTimeString).toLocaleDateString() + ' - ' + new Date(dateTimeString).toLocaleTimeString()
}