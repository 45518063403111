import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import CustomerSubscription from './CustomerSubscription';
import ParentAccountModal from './ParentAccountModal';

const ParentAccounts = () => {
  const [parentAccounts, setParentAccounts] = useState([]);

  const getCustomerParentAccounts = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + '/customers/parentAccounts/1',
      );
      if (res.data) {
        setParentAccounts(res.data);
      }
      console.log(res);
    } catch (error) {
      Bugsnag.notify(error)
      console.log(error);
    }
  };

  const [selectedParentAccount, setSelectedParentAccount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const openParentAccount = (parentAccount) => {
    setSelectedParentAccount(parentAccount);
    setShowModal(true);
  }

  const updateParentAccount = (parentAccount) => {
    const newParentAccounts = [];
    for (const acc of parentAccounts) {
      if (acc.id === parentAccount.id) {
        newParentAccounts.push(parentAccount);
        setSelectedParentAccount(parentAccount)
      } else {
        newParentAccounts.push(acc);
      }
    }
    setParentAccounts(newParentAccounts);
  }

  useEffect(() => {
    getCustomerParentAccounts();
  }, []);

  return (
    <>
      <div>
        <h1 className="text-center">Parent Accounts</h1>
        {parentAccounts && parentAccounts.length > 0 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Customer Number</th>
                <th>Email</th>
                <th>Name</th>
                <th>Remaining Hours</th>
                <th>Subscription</th>
                <th># Members</th>
              </tr>
            </thead>
            <tbody>
              {parentAccounts.map((parentAccount) => (
                <tr
                  style={{ cursor: 'pointer'}}
                  key={parentAccount.id}
                  onClick={() => openParentAccount(parentAccount)}
                >
                  <td>{parentAccount.fields.number}</td>
                  <td>{parentAccount.fields.email}</td>
                  <td>{parentAccount.fields.name}</td>
                  <td>{parentAccount.fields.hoursBalance}</td>
                  <td>
                    <CustomerSubscription customer={parentAccount} />
                  </td>
                  <td>
                    {parentAccount.fields.childAccounts
                      ? parentAccount.fields.childAccounts.length
                      : 0}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
      <ParentAccountModal showModal={showModal} setShowModal={setShowModal} parentAccount={selectedParentAccount} updateParentAccount={updateParentAccount} />
    </>
  );
};

export default ParentAccounts;
