import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';


import homeScreenBackground from '../assets/homescreen__background.png';
import transBack from '../assets/Tula_Pattern_10x10_LightGoldTransBack.png';
import homeScreenLogo from '../assets/tula__homescreen__logo.png';
import appStoreBadge from '../assets/app__store__badge.svg';
import googlePlayBadge from '../assets/google__play__badge.png';

const AccountDeletion = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('resize', (e) =>
      setWindowWidth(e.target.innerWidth),
    );
  }, []);

  return (
    <div className="home">
      <img
        src={windowWidth > 900 ? transBack : homeScreenBackground}
        alt="background"
        id="background-image"
      />
      <section style={{ zIndex: 999 }}>
        <img src={homeScreenLogo} alt="logo" />
        <h1
          className="header-text"
          style={{ textAlign: 'center', fontSize: 24 }}
        >
          Account Deletion Info:
        </h1>
        <h2
          className="header-text"
          style={{ textAlign: 'center', fontSize: 14 }}
        >
          In your TULA app, go to Settings > Account > Delete Account.
          <br />
          If you have an active subscription, you will first be required to cancel your subscription.
        </h2>
        <h3
          className="header-text"
          style={{ fontSize: 12, textAlign: 'center' }}
        >
          All user data will be deleted within 30 days of account deletion.
        </h3>
      </section>
    </div>
  );
};

export default AccountDeletion;
