import Dashboard from "../components/Dashboard";
import Login from "../components/Login"
import { FirebaseContextProvider, useFirebase } from "../firebase";

const Admin = () => {
    const { firebaseUser, authLoaded } = useFirebase();
    return (
      <>
        {!authLoaded ? (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="h-100">
            {firebaseUser ? <Dashboard /> : <Login />}
          </div>
        )}
      </>
    );
}

export default Admin