import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';


import homeScreenBackground from '../assets/homescreen__background.png';
import transBack from '../assets/Tula_Pattern_10x10_LightGoldTransBack.png';
import homeScreenLogo from '../assets/tula__homescreen__logo.png';
import appStoreBadge from '../assets/app__store__badge.svg';
import googlePlayBadge from '../assets/google__play__badge.png';

const AppDownload = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('resize', (e) =>
      setWindowWidth(e.target.innerWidth),
    );
  }, []);

  return (
    <div className="home">
      <img
        src={windowWidth > 900 ? transBack : homeScreenBackground}
        alt="background"
        id="background-image"
      />
      <section style={{ zIndex: 999 }}>
        <img src={homeScreenLogo} alt="logo" />
        <h1 className="header-text">Welcome!</h1>
        <h2
          className="header-text"
          style={{ textAlign: 'center', fontSize: 16 }}
        >
          Our web application is no longer supported
        </h2>
        <h2
          className="header-text"
          style={{ textAlign: 'center', fontSize: 14 }}
        >
          Please join us on our new mobile app!
        </h2>
        <div className="banner-icons">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.tula.tulaapp&hl=en_US&gl=US"
          >
            <img src={googlePlayBadge} alt="google play store" />
          </a>
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/tula-life-balanced/id1612236226"
          >
            <img
              src={appStoreBadge}
              style={{ padding: 7 }}
              alt="iOS app store"
            />
          </a>
        </div>
        <h3
          className="header-text"
          style={{ fontSize: 12, textAlign: 'center' }}
        >
          Questions or concerns?<br /> Please reach out to us at{' '}
          <a href="mailto:hello@tulabalanced.com">hello@tulabalanced.com</a>
        </h3>
      </section>
    </div>
  );
};

export default AppDownload;
