import React, { useContext } from 'react';
import {
  Switch,
  Route,
  Redirect,
  // BrowserRouter as Router,
  Router,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import AppDownload from './views/AppDownload';
import Admin from './views/Admin';
import AccountDeletion from './views/AccountDeletion';


ReactGA.initialize('G-P5GJEZDXQD');
const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const RedirectRoute = () => {
  return (
    <Redirect to={{ pathname: '/'}} />
  )
}

const NoMatch = ({ location }) => (
  <div>
    <h3>
      404 <code>{location.pathname}</code>
    </h3>
  </div>
);

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" exact component={AppDownload} />
      <Route path="/admin" exact component={Admin} />
      <Route path="/account-deletion" exact component={AccountDeletion} />
      <RedirectRoute path="/auth" exact />
      <RedirectRoute path="/invite" exact />
      <RedirectRoute path="/sign-up" exact />
      <RedirectRoute path="/confirm" exact />
      <RedirectRoute path="/forgot-confirm" exact />
      <RedirectRoute path="/request-invite" exact />
      <RedirectRoute path="/my-tula" />
      <RedirectRoute path="/profile" />
      <RedirectRoute path="/edit-profile" />
      <RedirectRoute path="/edit-preferences" />
      <RedirectRoute path="/create-profile" />
      <RedirectRoute path="/todos" />
      <RedirectRoute path="/new-todo" />
      <RedirectRoute path="/edit-todo/:todoId" />
      <RedirectRoute path="/schedule-todo/:todoId" />
      <RedirectRoute path="/hours" />
      <RedirectRoute path="/get-hours" />
      <RedirectRoute path="/contact-us" />

      <Route component={NoMatch} />
    </Switch>
  </Router>
);

export default Routes;
