import axios from 'axios';
import { useEffect, useState } from "react";
import { Accordion, Modal, Spinner, Table, useAccordionButton } from "react-bootstrap";
import { dateFormat, dateTimeFormat } from '../utils/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { sub } from 'date-fns';


const SubscriptionsModal =({customer, showModal, setShowModal}) => {

  const [subscriptions, setSubscriptions] = useState([]);

  const fetchSubscriptionData = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + '/subscriptions/customer/' + customer.fields.number + '?all=true');
      console.log(res.data);
      if (res.data) {
        // sort by startDate
        setSubscriptions(res.data.sort((a, b) => new Date(b.fields.startDate) - new Date(a.fields.startDate)));
      }

    } catch (error) {
      console.log(error);
    }
  }

  const getLatestSubHours = async (subscription) => {
    if (!subscription.fields.hours) {
      return;
    }
    const hour = subscription.fields.relatedHours[-1];
    const res = await axios.get(process.env.REACT_APP_API_URL + '/hours/' + hour);
    console.log(res.data);
  }

  useEffect(() => {
    if (customer) {
      fetchSubscriptionData();
    }

  }, [customer])

  const HourToggle = ({ subscription }) => {
    const [open, setOpen] = useState(false);
    const [balances, setBalances] = useState(null);
    const openClick = async () => {
      setOpen(!open);
      if (!balances) {
        const res = await axios.get(process.env.REACT_APP_API_URL + '/hours/customer/balances/' + subscription.fields.customerNumber[0]);
        if (res.data) {
          setBalances(res.data); 
        }
      }
    }; 
    return (
      <span type="button" onClick={openClick}>
        Balance Details{' '}
        <FontAwesomeIcon icon={open ? faChevronDown : faChevronUp} />
        <br />
        {open && (
          <>
            {balances ? (
              <div className="ms-2">
                <>
                  <span>
                    Current Subscription Balance:{' '}
                    {balances.currentSubscriptionBalance}
                  </span>
                  <br />
                  <span>
                    Persistent Hours Balance:{' '}
                    {customer.fields.hoursBalance - balances.currentSubscriptionBalance}
                  </span>
                  <br />
                  <span>
                    Latest Expiration:{' '}
                    {balances.latestExpirationHourRecord.fields.hours} hours on{' '}
                    {dateFormat(
                      balances.latestExpirationHourRecord.fields.createdAt,
                    )} (Hour #{balances.latestExpirationHourRecord.fields.number})
                  </span>
                  <br />
                  <span>
                    Latest Subscription Deposit:{' '}
                    {subscription.fields.latestSubscriptionHours} on{' '}
                    {dateFormat(
                      subscription.fields.latestSubscriptionDepositDate[0],
                    )} (Hour #{subscription.fields.latestSubscriptionHourNumber})
                  </span>
                </>
              </div>
            ) : (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </>
        )}
      </span>
    );
  };

  const StripeToggle = () => {
    const [open, setOpen] = useState(false);
    const [payments, setPayments] = useState([]);
    const openClick = async () => {
      setOpen(!open);
      if (payments.length === 0) {
        const res = await axios.get(process.env.REACT_APP_API_URL + '/customers/payments/' + customer.fields.number);
        if (res.data && res.data.data.length > 0) {
          console.log(res.data.data);
          setPayments(res.data.data); 
        }
      }
    }; 
    return (
      <span type="button" onClick={openClick}>
        Latest Payment Details{' '}
        <FontAwesomeIcon icon={open ? faChevronDown : faChevronUp} />
        <br />
        {open && (
          <>
            {payments.length > 0 ? (
              <div className="ms-2">
                {payments.map((payment, index) => 
                  <span key={index}>
                    {new Date(payment.created * 1000).toLocaleDateString()}: ${payment.amount / 100} for <strong>{payment.description}</strong> ({payment.status})
                    <br />
                  </span>
                )}
              </div>
            ) : (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </>
        )}
      </span>
    );
  };


  return (
    <Modal
      size="lg"
      show={showModal}
      backdrop="static"
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{customer.fields.name} Subscription Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {subscriptions.map((subscription, index) => (
          <div key={index}>
            <h6>Subscription Number {subscription.fields.number} -  Stripe ID {subscription.stripeSubscription.id}</h6>
            <div>
            Customer Hours Balance: {customer.fields.hoursBalance}
            <br />
            Subscription Start Date: {dateFormat(subscription.fields.startDate)}
            <br />
              Airtable Status: {subscription.fields.status}
              {subscription.fields.cancelled && subscription.fields.cancelDate && 
              (<strong> - Cancelled on {dateFormat(subscription.fields.cancelDate)}</strong>) && 
              (!subscription.fields.cancelled && subscription.stripeSubscription.cancel_at_period_end && (<strong>Set to cancel at period end</strong>))}
              <br />
              Stripe Status: {subscription.stripeSubscription.status}
              <br />
              <HourToggle subscription={subscription}>Latest Subscription Hour</HourToggle>
              <br />
              <StripeToggle />
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
}

export default SubscriptionsModal;