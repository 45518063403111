import axios from 'axios';
import { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { dateTimeFormat } from '../utils/format';



const HoursModal =({customer, showModal, setShowModal}) => {


  const [hours, setHours] = useState([]);


  const fetchCustomerHours = async () => {
    try {

      const res = await axios.get(process.env.REACT_APP_API_URL + '/hours/customer/' + customer.fields.number);
      setHours(res.data.reverse())

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (customer) {
      fetchCustomerHours();
    }

  }, [customer])


  return (
    <Modal size="xl" show={showModal} backdrop="static" onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{customer.fields.name} Hours History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Hours</th>
              <th>Type</th>
              <th>Source</th>
            </tr>
          </thead>
          <tbody>
          {hours.map((hour, index) => (
          <tr key={index}>
            <td>{hour.fields.number}</td>
            <td>{dateTimeFormat(hour.fields.createdAtOverride || hour.fields.createdAt)}</td>
            <td>{hour.fields.hours}</td>
            <td>{hour.fields.type}</td>
            <td>{hour.fields.source}</td>
          </tr>
        ))}
          </tbody>
        </Table>
        
      </Modal.Body>
    </Modal>
  )
}

export default HoursModal;