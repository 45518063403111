import { Card } from "react-bootstrap";
import AsyncButton from "./AsyncButton";


const AssistantCard = ({ assistant, resetPassword }) => {
    if (!assistant || !assistant.fields) return null
    return (
      <Card className="my-5" style={{ width: '24rem' }}>
        <Card.Img
          variant="top"
          height={200}
          style={{ objectFit: 'contain' }}
          src={
            assistant.fields.pictureS3Url
              ? assistant.fields.pictureS3Url
              : 'https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg'
          }
        />
        <Card.Body className="text-center">
          <Card.Title>
            {assistant.fields.name}
            <br /># {assistant.fields.number}
          </Card.Title>
          <Card.Text>
            <span className="fs-6">{assistant.fields.email}</span>
          </Card.Text>
          <AsyncButton onClick={resetPassword} buttonClass="btn btn-danger">Reset Password</AsyncButton>
        </Card.Body>
      </Card>
    );
}

export default AssistantCard;