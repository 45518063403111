import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Router from './Router';

import './App.scss';
import './sass/shared.scss';
import './sass/authStyles.scss';
import './sass/profileStyles.scss';
import './sass/dashStyles.scss';
import './sass/hoursStyles.scss';
import './sass/mobileStyles.scss';
import { FirebaseContextProvider } from './firebase';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

Bugsnag.start({
  apiKey: '8ea73684ea71b8473d8640334974480e',
  plugins: [new BugsnagPluginReact()]
})


const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <FirebaseContextProvider>
          <Router />
        </FirebaseContextProvider>
      </div>
    </Elements>
  );
};

export default App;
