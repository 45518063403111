import { Button, Table } from 'react-bootstrap';
import AsyncButton from './AsyncButton';
import CustomerSubscription from './CustomerSubscription';

const ChildAccountTable = ({
  childAccounts,
  parentAccount,
  removeFromParentAccount,
  transferHours,
}) => {
  const alertRemoveAccount = async (childAccount) => {
    const remove = window.confirm(
      `Are you sure you want to remove\n${childAccount.fields.name} from ${parentAccount.fields.name}'s account?\nPreviously transferred hours will not be affected`,
    );
    if (remove) {
      await removeFromParentAccount(childAccount, parentAccount);
    }
  };

  const addHours = async (childAccount) => {
    const hours = prompt(`How many hours do you want to transfer from account #${parentAccount.fields.number} to account #${childAccount.fields.number}?`);
    if (hours) {
      if (hours > parentAccount.fields.hoursBalance) {
        alert(`You can't transfer more hours than the parent account has available!`);
        return;
      }
      await transferHours(childAccount, hours);
    }
  }

  const removeHours = async (childAccount) => {
    const hours = prompt(`How many hours do you want to transfer from account #${childAccount.fields.number} to account #${parentAccount.fields.number}?`);
    if (hours) {
      if (hours > childAccount.fields.hoursBalance) {
        alert(`You can't transfer more hours than the child account has available!`);
        return;
      }
      await transferHours(childAccount, hours, true);
    }
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Customer Number</th>
          <th>Email</th>
          <th>Name</th>
          <th>Remaining Hours</th>
          <th>Subscription</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {childAccounts.map((childAccount) => (
          <tr key={childAccount.id}>
            <td>{childAccount.fields.number}</td>
            <td>{childAccount.fields.email}</td>
            <td>{childAccount.fields.name}</td>
            <td>{childAccount.fields.hoursBalance}</td>
            <td>
              <CustomerSubscription customer={childAccount} />
            </td>
            <td className="d-flex flex-column h-100 gap-2">
              <AsyncButton onClick={async () => await addHours(childAccount)} size="sm">Add hours</AsyncButton>
              {/* <AsyncButton onClick={async () => await removeHours(childAccount)} size="sm" variant="secondary">Remove hours</AsyncButton> */}
              <AsyncButton
                onClick={async () => await alertRemoveAccount(childAccount)}
                size="sm"
                variant="danger"
              >
                Remove Linkage
              </AsyncButton>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ChildAccountTable;
