import { useEffect, useState } from "react";
import { Col, Modal, Nav, Pagination, Row, Tab } from "react-bootstrap"
import Chat from "./Chat";


const TodosModal = ({todoData, customer, showModal, setShowModal}) => {
  
  const [category, setCategory] = useState('all');
  const [todos, setTodos] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedTodo, setSelectedTodo] = useState(null);
  
  const [pageTodos, setPageTodos] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (todoData && Object.keys(todoData).length > 0) {
      const todosInCategory = todoData[category];
      if (todosInCategory) {
        const todosInPage = todosInCategory.slice((page - 1) * pageSize, page * pageSize);
        
        setPageTodos(todosInPage);
      }
      setTodos(todoData[category])

    }
  }, [todoData, category, page])

  return (
    <Modal
      size="xl"
      show={showModal}
      backdrop="static"
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{customer.fields.name} Todos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Nav variant="tabs" defaultActiveKey="all" onSelect={(selectedKey) => setCategory(selectedKey)}>
          {Object.keys(todoData).map((category, index) => (
            <Nav.Item key={'category-' + index}>
              <Nav.Link eventKey={category} className="text-capitalize">{category}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        <br />
        {pageTodos && pageTodos.length > 0 && (
          <div className="d-flex flex-column ">
            <Tab.Container id="todos-tab">
              <Row>
                <Col sm={3} className="border-end border-1">
                  <Nav variant="pills" className="flex-column">
                    {pageTodos.map((todo, index) => (
                      <Nav.Item key={'tab-' + index}>
                        <Nav.Link eventKey={todo.id} onClick={() => setSelectedTodo(todo)}>
                          {todo.fields.itemNames[0]}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
                <Col sm={5}>
                  <Tab.Content>
                    {pageTodos.map((todo, index) => (
                      <Tab.Pane key={'pane-' + index} eventKey={todo.id}>
                            <span className="fw-bold" >Status: </span>
                            <span style={{fontSize: '.75rem'}} className="text-capitalize">{todo.fields.listStatus}</span>
                            <br />
                            <span className="fw-bold" >Description: </span>
                            <span style={{fontSize: '.75rem'}}>{todo.fields.itemDescriptions ? todo.fields.itemDescriptions[0] : ''}</span>
                            <br />
                            <span className="fw-bold" >Assistant: </span>
                            <span style={{fontSize: '.75rem'}}>{todo.fields.assistantName}</span>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
                {selectedTodo && selectedTodo.fields.assistantName && (
                  <Col sm={4}>
                    <Chat showControls={false} customerName={customer.fields.name} assistantName={selectedTodo.fields.assistantName ? selectedTodo.fields.assistantName[0] : null}/>
                  </Col>
                  )}
              </Row>
            </Tab.Container>
            <Pagination className="align-self-center">
              <Pagination.Prev
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
              />
              {page > 1 && (
                <Pagination.Item onClick={() => setPage(page - 1)}>
                  {page - 1}
                </Pagination.Item>
              )}
              <Pagination.Item onClick={() => setPage(1)} active={true}>
                {page}
              </Pagination.Item>
              {page < Math.ceil(todos.length / pageSize) && (
                <Pagination.Item onClick={() => setPage(page + 1)}>
                  {page + 1}
                </Pagination.Item>
              )}
              <Pagination.Next
                onClick={() => setPage(page + 1)}
                disabled={page === Math.ceil(todos.length / pageSize)}
              />
            </Pagination>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default TodosModal;