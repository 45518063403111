import { Card, Carousel, Container, Form, Table } from 'react-bootstrap';
import { useState } from 'react';
import AsyncButton from './AsyncButton';
import axios from 'axios';
import { dateFormat } from '../utils/format';
import CustomerCard from './CustomerCard';
import Bugsnag from '@bugsnag/js';
import AssistantCard from './AssistantCard';

const UserSearch = ({ parentAccount, addToParentAccount, addChat }) => {
  const [userType, setUserType] = useState('client');
  const [lookupType, setLookupType] = useState('email');
  const [errorMessage, setErrorMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [activeUser, setActiveUser] = useState(null);
  const [activeCustomerIndex, setActiveCustomerIndex] = useState(0);

  const search = async () => {
    try {
      if (!searchTerm) {
        setErrorMessage('Please enter a search term');
      }
      const route = userType === 'client' ? 'customers' : 'assistants';
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          `/${route}/` +
          lookupType +
          '/' +
          searchTerm,
      );
      const userRes = res.data;

      if (userRes) {
        if (lookupType === 'number') {
          setActiveUser(userRes)
          setUsers([userRes]);
          setErrorMessage('');
        } else {
          setActiveUser(userRes[0])
          setUsers(userRes);
          setErrorMessage('');
        }
      } else {
        setErrorMessage('User not found');
      }
    } catch (error) {
      console.log(error);
      Bugsnag.notify(error);
      setErrorMessage('Error searching for User');
    }
  };

  const addToParent = async () => {
    await addToParentAccount(activeUser, parentAccount);
    setCustomer(null);
  };

  const resetPassword = async () => {
    const res = await axios.post(
      process.env.REACT_APP_API_URL + '/authentication/requestPasswordReset',
      {
        email: activeUser.fields.email,
      },
    )
    if (res.status === 200) {
      alert('Password reset email sent');
    } else {
      alert('Error sending password reset email');
    }
  }

  return (
    <>
      {!parentAccount ? (
        <h1>
          <span className="text-capitalize">{userType}</span> Lookup
        </h1>
      ) : (
        <h5>Search for {userType} to add</h5>
      )}
      <div className="d-flex flex-row mt-4">
        <p className="pe-4">User Type: </p>
        <Form>
          <Form.Check
            inline
            label="Client"
            name="searchType"
            type="radio"
            id="client-radio"
            onChange={() => {
              setActiveUser(null);
              setUsers([]);
              setUserType('client');
            }}
            checked={userType === 'client'}
          />
          <Form.Check
            inline
            label="Assistant"
            name="searchType"
            type="radio"
            id="assistant-radio"
            onChange={() => {
              setActiveUser(null);
              setUsers([]);
              setUserType('assistant');
            }}
            checked={userType === 'assistant'}
          />
        </Form>
      </div>
      <div className="d-flex flex-row mt-4">
        <p className="pe-4">Lookup by: </p>
        <Form>
          <Form.Check
            inline
            label="Email"
            name="searchType"
            type="radio"
            id="email-radio"
            onChange={() => setLookupType('email')}
            checked={lookupType === 'email'}
          />
          <Form.Check
            inline
            label="Number"
            name="searchType"
            type="radio"
            id="number-radio"
            onChange={() => setLookupType('number')}
            checked={lookupType === 'number'}
          />
        </Form>
      </div>
      <div className="d-flex flex-row">
        <Form.Control
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={lookupType === 'email' ? `User Email` : `User Number`}
        />
        <AsyncButton onClick={search} buttonClass="btn btn-primary ms-2">
          Search
        </AsyncButton>
      </div>
      {errorMessage && <p className="text-danger pt-2">{errorMessage}</p>}
      {users.length > 0 && (
        <Table striped bordered hover className="mt-4 w-75">
          <thead>
            <tr>
              <th>
                <span className="text-capitalize">{userType}</span> Number
              </th>
              <th>
                <span className="text-capitalize">{userType}</span> Name
              </th>
              <th>
                <span className="text-capitalize">{userType}</span> Email
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr
                key={user.id}
                className={
                  activeUser.id === user.id ? 'table-active fw-bold' : ''
                }
                onClick={() => setActiveUser(user)}
              >
                <td>{user.fields.number}</td>
                <td>{user.fields.name}</td>
                <td>{user.fields.email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {userType === 'client' ? (
        <>
          {activeUser && (
            <CustomerCard resetPassword={resetPassword} customer={activeUser} parentAccount={parentAccount} />
          )}
          {activeUser &&
            parentAccount &&
            !activeUser.fields.isParentAccount &&
            !activeUser.fields.parentAccount && (
              <AsyncButton onClick={addToParent} buttonClass="">
                Add to Parent Account
              </AsyncButton>
            )}
          {activeUser && addChat && (
            <AsyncButton
              onClick={() => addChat(activeUser)}
              buttonClass="btn btn-primary ms-2"
            >
              Add Chat
            </AsyncButton>
          )}
        </>
      ) : (
        <AssistantCard resetPassword={resetPassword} assistant={activeUser} />
      )}
      
    </>
  );
};

export default UserSearch;
